// TEST SCRIPT
console.log("bundle script working");

if (process.env.NODE_ENV !== "production") {
  console.log("Looks like we are in development mode");
}

// CONTENT MENU DISABLE/ENABLE [COPY/PAST]
const content = document.querySelector(".content");
if (content) {
  content.addEventListener("contextmenu", (e) => e.preventDefault());
}

// IMPORTS:
// Stylesheet
import "../styles/css/style.css";
// Documents
import "../docs/sitemap.xml";
import "../docs/ads.txt";
import "../docs/robots.txt";
import "../docs/.htaccess";

// Assets
import "../assets/images/logo/favicon.ico";
import "../assets/images/webp/banner.webp";
import "../assets/images/webp/about.webp";
import "../assets/images/webp/poetry.webp";
import "../assets/images/webp/gallery.webp";
import "../assets/images/webp/blog.webp";
import "../assets/images/webp/stories.webp";
import "../assets/images/webp/reviews.webp";
import "../assets/images/webp/books.webp";
import "../assets/images/webp/projects.webp";
import "../assets/images/webp/journal.webp";
import "../assets/images/webp/insecurity.webp";
import "../assets/images/webp/leftuntouched.webp";
import "../assets/images/webp/heavensway.webp";

// % SHOWCASE MEDIA

//Assets - Showcase
import "../assets/gifs/webp/hate-love-gif.webp";
// Assets - Showcase Mobile 
import "../assets/gifs/insecurity-mobile.gif";

// # SEO META TAG IMAGES

// Assets - Banner
import "../assets/images/webp/webnovel_banner.webp";
import "../assets/images/webp/after_school_webcover.webp";
import "../assets/images/webp/the-lonely-king_banner.webp";
import "../assets/images/webp/hate-love.webp";

// ADD-ONS:
// Fonts
// import "../scripts/js/demos/all.js";
